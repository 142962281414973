<template>
<div class="pt-3 pb-3">
  <label class="text-title-3">  
    {{ title }} <span v-if="error" class="error--text text-title-4"> *กรุณาเลือกคำตอบ</span></label>
    <v-row dense>
      <v-col v-for="(item, index) in items" :key="index" cols="6">
        <BookingFilledCardWidget :title="item.title" :index="index" :active.sync="item.active" @togle="togle"/>
      </v-col>
    </v-row>
</div>
</template>

<script>
import BookingFilledCardWidget from "@/components/booking/BookingFilledCardWidget";

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    BookingFilledCardWidget,
  },
  props: ["title","value","error","choices"],
  data() {
    return {
      items: [],

    }
  },
  watch: {
    choices(val) {
       if(val){
         this.items = []
      for(let i = 0 ; i< val.length ; i++){
        // console.log(this.value)
        if(this.value && this.value.value===val[i].value){
           this.items.push({title:val[i].text, value: val[i].value, active: true })
        }else{
           this.items.push({title:val[i].text, value: val[i].value, active: false })
        }
      }
    }
    }
  },
  mounted () {
    if(this.choices){
      for(let i = 0 ; i< this.choices.length ; i++){
        // console.log(this.value)
        if(this.value && this.value.value===this.choices[i].value){
           this.items.push({title:this.choices[i].text, value: this.choices[i].value, active: true })
        }else{
           this.items.push({title:this.choices[i].text, value: this.choices[i].value, active: false })
        }
      }
    }
  },
  methods: {
    togle(val) {
      for(let i=0 ; i< this.items.length;i++){
          if(val!==i){
           this.items[i].active =false
          }else{
            this.$emit('change',this.items[i])
          }
      }
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>