<template>
<v-app>
    <SingleAppBar title="บันทึกค่าน้ำตาล" :backward="true" name="healthrecord"/>
    <v-main class="natural lighten-4 fill-height">
      <v-container>
          <v-row>
              <v-col cols="12">
                  <v-card flat color="transparent">
                      <v-card-text>  
                        <v-form ref="form" v-model="valid" lazy-validation>
<TextField v-model="bsLevel" v-mask="'###'" :title="'ค่าน้ำตาลปลายนิ้ว'" placeholder="mg/dl" required :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"/>
<!-- <TextField v-model="hb1c" v-mask="'###'" :title="'ค่าน้ำตาลสะสม'" placeholder="mmol/mol" required :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"/> -->
<BookingSelectTwoColumn v-model="bsTest" v-bind="tests"/>
<DatePicker v-model="date" :title="'วันที่'" />
<TimePicker v-model="time" :title="'เวลา'"/>
<!-- <TextField v-model="note" :title="'Note'"/> -->
                        </v-form>
 <v-btn block large color="primary text-title-2" @click="postVitalSign()">ยืนยันการบันทึก</v-btn>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
        <!-- <ListCard/> -->
      </v-container>
       <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
      </v-main
    >
</v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
import BookingSelectTwoColumn from "@/components/booking/BookingSelectTwoColumn";
// import {postVitalSign} from "@/api/"
import {postVSign} from "@/api/cms"
import TextField from "@/components/healthrecord/TextField";
import { mask } from "vue-the-mask";
import TimePicker from "@/components/common/TimePicker";
import DatePicker from "@/components/common/DatePicker";
//import ListCard from "@/components/inbox/ListCard";

export default {
  name: "SaveGlucose",
  directives: { mask },
  components: {
    SingleAppBar,
    TextField,
    TimePicker,
    DatePicker,
    BookingSelectTwoColumn
    //ListCard
  },
  data() {
    return {
      valid: false,
      state: false,
      items: [],
      tests: {
          title: 'ช่วงเวลา',
          choices: [
              {text: 'ก่อนอาหาร' , value: 'ก่อนอาหาร'},
              {text: 'หลังอาหาร' , value: 'หลังอาหาร'}
          ]
      },
      loading: false,
      role: null,
      bsLevel: null,
      bsTest: null,
      hb1c: null,
      time: this.$dayjs(new Date()).format("HH:mm"),
      date: this.$dayjs(new Date()).format("YYYY-MM-DD"),
      note: ''
      
    }
  },
  methods: {
   postVitalSign () {
      this.loading = true
      const site = this.$offlineStorage.get("site");
      // console.log(new Date(this.date + ' '+ this.time).getTime())
      if(site && site.groupId) {

        let data = {
          blood_pressure:null,
          temperature: null,
          body_component: null,
          glucose:  [
            {
              device_timestamp: this.$dayjs(this.date + ' '+ this.time).unix(),
              glucose_condensation: Number(this.bsLevel),
              measure_mode: "M"
            }],
          oxymetry: null
        }
        postVSign(data, message=> {
          if (message.data){
            this.$router.push({name:'healthrecord'})
          }
        },error=>{
          console.log(error)
        })


        // postVitalSign({ vType: "glucose",
        //     vTimestamp: this.$dayjs(this.date + ' '+ this.time).unix(),
        //     bsLevel: this.bsLevel,
        //     hb1c: this.hb1c,
        //     bsTest: this.bsTest,
        //     measureMode: "M",
        //     remark: this.note,
        //     groupId: site.groupId
        //     },message=>{
        //       this.loading = false
        //       if(message.data.code ===1){
        //             this.$router.push({name:'healthrecord'})
        //       }
              
        //   },error=>{
        //       console.log(error)
        //   })
      }
      
      // else{
      //    postVitalSign({ vType: "glucose",
      //       vTimestamp: this.$dayjs(this.date + ' '+ this.time).unix(),
      //       bsLevel: this.bsLevel,
      //       hb1c: this.hb1c,
      //       bsTest: this.bsTest,
      //       measureMode: "M",
      //       remark: this.note
      //       },message=>{
      //         this.loading = false
      //         if(message.data.code ===1){
      //               this.$router.push({name:'healthrecord'})
      //         }
              
      //     },error=>{
      //         console.log(error)
      //     })
      // }
     
    
    },

    async fetchData(){
    //   this.role = this.$offlineStorage.get('role')
    //   const site = this.$offlineStorage.get("site");
    //   const tenant = this.$offlineStorage.get("tenant");
    //   if (navigator.onLine){
    //        this.loading = true
    //     try{    
    //     let itemresp = await announcement({tenantId: tenant.tenantId ,siteId: site.siteId,offset:0,limit:100 })
    //     for(let i =0 ;i<itemresp.data.result.length;i++){
    //       let resp = await announcementBanner({tenantId: tenant.tenantId ,siteId: site.siteId,bannerName: itemresp.data.result[i].bannerName})
    //       itemresp.data.result[i].img = resp.data.result
    //     }
    //     this.items = itemresp.data.result
    //     }catch(err){
    //       console.log(err)
    //     }finally{
    //       this.loading = false
    //     }
    //   }else{
    //      this.loading = true
    //      this.items =  this.$offlineStorage.get('announcement')
    //      this.loading = false
    //   }
    
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
};
</script>

<style lang="scss" scoped>

</style>